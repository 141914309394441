import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import '../../dist/css/bolt-css.css';
import { Sidebar } from '../../dist/js/bolt';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sidebar-js"
    }}>{`Sidebar JS`}</h1>
    <p>{`O componente Sidebar monta uma barra lateral com itens de menu formatados segundo o design system da Pismo.`}</p>
    <br />
    <h2 {...{
      "id": "propriedades"
    }}>{`propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// propriedades aplicadas ao Header da sidebar
export interface IHeader {
  label: string; // texto do botão
}

// propriedades aplicadas aos botões
export interface SidebarButton extends MenuButtonProps {
  id: number | string; // esse id é devolvido no evento onSelected
  selected?: boolean; // indica que o elemento estará selecionado no inicio
}

// propriedades requeridas no construtor
export interface SidebarProps {
  container: HTMLElement; // elemento HTML onde será construído a sidebar
  header: IHeader; // header da sidebar
  content: SidebarButton[]; // Array de botões que irá compor o conteudo da sidebar
  footerLabel: string; // texto do footer da sidebar
  autoCollapsed?: boolean; // se false a sidebar apenas dispara o evento onCollapsed - default = true
}

// construtor, deve receber SidebarProps
export interface ISidebarConstructor {
  new (props: SidebarProps): ISidebar;
}

export interface ISidebar {
  collapsed: boolean; // retorna o estado da sidebar, default - false, se passar o valor true a sidebar se contrai
  destroy: () => void; // método público, remove todos os elementos da página

  onSelected?: (id: number | string) => void; // evento disparado quando um botão é selecionado, envia o id do elemento como argumento
  onCollapsed?: () => void; // evento disparado quando clicado no botão de collapse
}
`}</code></pre>
    <br />
    <br />
    <h2 {...{
      "id": "exemplo"
    }}>{`Exemplo`}</h2>
    <br />
    <Playground __position={3} __code={'() => {\n  const [sidebar, setSidebar] = useState(null)\n  const [currentPage, setCurrentPage] = useState(\'/statement\')\n  const sidebarRef = useRef()\n  useEffect(() => {\n    if (sidebarRef.current && !sidebar) {\n      setSidebar(\n        new Sidebar({\n          container: sidebarRef.current,\n          header: { icon: \'bars\', label: \'CustomerApp\' },\n          content: [\n            {\n              icon: \'money\',\n              level: \'first\',\n              label: \'Statement\',\n              selected: true,\n              id: \'/statement\',\n            },\n            {\n              icon: \'accounts\',\n              level: \'first\',\n              label: \'Profile\',\n              id: \'/profile\',\n            },\n            { icon: \'cards\', level: \'first\', label: \'Cards\', id: \'/cards\' },\n            {\n              icon: \'calendar\',\n              level: \'first\',\n              label: \'Activity\',\n              id: \'/activity\',\n            },\n            { icon: \'edit\', level: \'first\', label: \'Notes\', id: \'/notes\' },\n          ],\n          footerLabel: \'v1.6.0\',\n        }),\n      )\n    }\n    if (sidebar) {\n      sidebar.onSelected = handleSelected\n    }\n    return () => {\n      if (sidebar) sidebar.destroy()\n    }\n  }, [sidebarRef.current, sidebar])\n  const handleSelected = id => {\n    setCurrentPage(id)\n  }\n  return (\n    <div className=\"tw-flex tw-h-screen\">\n      <div ref={sidebarRef} data-open={false}></div>\n      <div className=\"tw-flex tw-flex-col tw-p-6\">\n        <p className=\"tw-body3\">Página selecionada:</p>\n        <h3 className=\"tw-h4 tw-mt-6\">{currentPage}</h3>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      Sidebar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [sidebar, setSidebar] = useState(null);
        const [currentPage, setCurrentPage] = useState('/statement');
        const sidebarRef = useRef();
        useEffect(() => {
          if (sidebarRef.current && !sidebar) {
            setSidebar(new Sidebar({
              container: sidebarRef.current,
              header: {
                icon: 'bars',
                label: 'CustomerApp'
              },
              content: [{
                icon: 'money',
                level: 'first',
                label: 'Statement',
                selected: true,
                id: '/statement'
              }, {
                icon: 'accounts',
                level: 'first',
                label: 'Profile',
                id: '/profile'
              }, {
                icon: 'cards',
                level: 'first',
                label: 'Cards',
                id: '/cards'
              }, {
                icon: 'calendar',
                level: 'first',
                label: 'Activity',
                id: '/activity'
              }, {
                icon: 'edit',
                level: 'first',
                label: 'Notes',
                id: '/notes'
              }],
              footerLabel: 'v1.6.0'
            }));
          }

          if (sidebar) {
            sidebar.onSelected = handleSelected;
          }

          return () => {
            if (sidebar) sidebar.destroy();
          };
        }, [sidebarRef.current, sidebar]);

        const handleSelected = id => {
          setCurrentPage(id);
        };

        return <div className="tw-flex tw-h-screen">
        <div ref={sidebarRef} data-open={false}></div>
        <div className="tw-flex tw-flex-col tw-p-6">
          <p className="tw-body3">Página selecionada:</p>
          <h3 className="tw-h4 tw-mt-6">{currentPage}</h3>
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      